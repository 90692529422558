import styled from "styled-components";
import ImageBottomBarBg from "../assets/bg-bottomBar.png";
import Typography from "../components/common/Typography";
import theme from "../utils/theme";
import IconFacebook from "../assets/ic-facebook.svg";
import IconInstagram from "../assets/ic-instagram.svg";
import IconYoutube from "../assets/ic-youtube.svg";
import { useTranslation } from "react-i18next";
import { breakpoint } from "utils/responsive";
import preval from "preval.macro";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTE_BASE } from "Route";
import { getLocalisedString } from "common/utils/stringHelper";

const Container = styled.footer`
  height: 163px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  padding: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${ImageBottomBarBg});
  z-index: 500;
  /* background-color: ${theme.greyBg}; */

  @media ${breakpoint.mobile} {
    height: 401.5px;
  }

  @media (min-width: 1440px) {
    height: 200px;
  }
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SocialIcon = styled.img`
  cursor: pointer;
  margin-right: 10px;
`;

const ItemContainer = styled.div`
  display: flex;

  @media ${breakpoint.mobile} {
    width: 100%;
    flex-direction: column;
    gap: 23px;
  }
`;

const Item = styled.div`
  margin-right: 10px;
  cursor: pointer;

  @media ${breakpoint.mobile} {
    text-align: center;
  }
`;
type FooterItem =
  | { name: string; url: Url }
  | { name: string; routeName: string };
const ITEM_LIST: FooterItem[] = [
  /* {
    name: "termsAndConditions",
    url: {
      en: "https://www.google.com",
      zh: "https://www.youtube.com",
    },
  },
  {
    name: "cookiePolicy",
    url: {
      en: "https://www.google.com",
      zh: "https://www.youtube.com",
    },
  }, */
  // {
  //   name: "faq",
  //   routeName: `${ROUTE_BASE.FAQ}`,
  // },
  // {
  //   name: "privacyPolicy",
  //   routeName: `${ROUTE_BASE.TNC}/privacy-policy`,
  // },
  // {
  //   name: "cancelPolicy",
  //   routeName: `${ROUTE_BASE.TNC}/cancel-policy`,
  // },
  // {
  //   name: "contactSaiShaSportClub",
  //   routeName: `${ROUTE_BASE.CONTACT_US}`,
  // },
];

interface Url {
  en: string;
  zh: string;
}

const BottomBar = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("navBar");

  const handlClickUrl = (url: Url) => {
    const urlLang = i18n.language === "zh" ? url?.zh : url?.en;
    window.open(urlLang, "_blank");
  };

  const handleSocialMedia = (url: string) => {
    window.open(url, "_blank");
  };

  const handleClickInternalUrl = (routeName: string) => {
    navigate(`/${i18n.language}/${routeName}`);
  };

  /**202402291430 */
  const buildVersion = preval`
  const dayjs = require('dayjs')
  module.exports = dayjs().format('YYYYMMDDHHmm');
  `;

  return (
    <Container>
      {/* Desktop only */}
      <div className="mb-[15px] hidden md:block">
        <ItemContainer style={{ display: "flex" }}>
          {/* <SocialIcon
            src={IconFacebook}
            onClick={() =>
              handleSocialMedia("https://www.facebook.com/saisha.com.hk/")
            }
          /> */}
          {/* <SocialIcon
            src={IconYoutube}
            onClick={() => handleSocialMedia("https://www.youtube.com")}
          /> */}
          {/* <SocialIcon
            src={IconInstagram}
            onClick={() =>
              handleSocialMedia("https://www.instagram.com/saisha.com.hk/")
            }
          /> */}
        </ItemContainer>
      </div>
      <Menu>
        {/* Desktop only */}
        <div className="hidden md:block">
          <Typography color={theme.white}>
            {getLocalisedString(
              "Copyright ©️ 2024 GO PARK SPORTS. All Rights Reserved.",
              "Copyright ©️ 2024 GO PARK SPORTS 保留一切權利"
            )}
          </Typography>
          {/* <Typography color={theme.white}>
            {t("footer.version", { number: buildVersion })}
          </Typography> */}
        </div>
        {/* All view port */}
        <ItemContainer>
          {ITEM_LIST.map((item) => {
            return (
              <Item
                key={item.name}
                onClick={() =>
                  "url" in item
                    ? handlClickUrl(item?.url)
                    : handleClickInternalUrl(item?.routeName)
                }
              >
                <Typography color={theme.white}>
                  {t(`footer.${item.name}`)}
                </Typography>
              </Item>
            );
          })}
          {/* Mobile only  */}
          <div className="mt-[9px] flex justify-center gap-[26.67px] md:hidden">
            {/* <SocialIcon
              src={IconFacebook}
              onClick={() =>
                handleSocialMedia("https://www.facebook.com/saisha.com.hk")
              }
            /> */}
            {/* <SocialIcon
              src={IconYoutube}
              onClick={() => handleSocialMedia("https://www.youtube.com")}
            /> */}
            {/* <SocialIcon
              src={IconInstagram}
              onClick={() =>
                handleSocialMedia("https://www.instagram.com/saisha.com.hk/")
              }
            /> */}
          </div>
          <div className="m-[23px_auto_12.5px] md:hidden">
            <Typography textAlign="center" color={theme.white}>
              {getLocalisedString(
                "Copyright ©️ 2024 GO PARK SPORTS. All Rights Reserved.",
                "Copyright ©️ 2024 GO PARK SPORTS 保留一切權利"
              )}
            </Typography>
            {/* <Typography textAlign="center" color={theme.white}>
              {t("footer.version", { number: buildVersion })}
            </Typography> */}
          </div>
        </ItemContainer>
      </Menu>
    </Container>
  );
};

export default BottomBar;
