import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import cn from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem } from "@mui/material";
import { UserViewModelContext } from "common/viewModels/UserViewModel";
import { ROUTE_BASE } from "Route";
import IconNews from "../../assets/ic-navBar-news.svg";
import Space from "../common/Space";
import Image from "../common/Image";
import IconNotification from "../../assets/ic-navBar-notification.svg";
import IconGuest from "../../assets/bg-avatar-placeholder.png";
import Typography from "../../components/common/Typography";
import ProfileImage from "../../components/common/images/ProfileImage";
import theme from "../../utils/theme";
import LoginIconButton from "./LoginIconButton";
import Weather from "./Weather";
import { NotificationType } from "models/Notification";

const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

const Item = styled.div`
  cursor: pointer;
`;

// const ITEM_LIST = [
//   {
//     name: "sportPass",
//     url: ROUTE_BASE.SPORT_PASS,
//   },
//   {
//     name: "news",
//     url: ROUTE_BASE.NEWS,
//   },
//   {
//     name: "events",
//     url: ROUTE_BASE.EVENT,
//   },
//   {
//     name: "discover",
//     url: ROUTE_BASE.DISCOVER,
//   },
//   {
//     name: "packageOffer",
//     url: ROUTE_BASE.PACKAGE_OFFER,
//   },
// ];

const BookingDropdown: React.FC<{
  className?: React.HTMLProps<HTMLElement>["className"];
  handleClickBookMenu: (link: string) => void;
}> = ({ className, handleClickBookMenu }) => {
  const { t } = useTranslation("navBar");

  const listData = useMemo(
    () => [
      { key: "topBar.facilityBooking", url: ROUTE_BASE.FACILITY_BOOKING },
      { key: "topBar.monthlyProgram", url: ROUTE_BASE.MONTHLY_PROGRAMME },
      { key: "topBar.privateCoaching", url: ROUTE_BASE.PRIVATE_COACH },
    ],
    []
  );

  return (
    <div className="relative z-[10] bg-white border border-solid border-[#D8D8D8] rounded-[5px]">
      <div className={cn(className, "rounded-[5px] overflow-hidden")}>
        <ul className="">
          {listData.map((each) => (
            <Link to={each.url} key={each.key}>
              <li
                className={cn(
                  "w-[149px]",
                  "p-[_15px_18px]",
                  "text-xs",
                  "font-medium",
                  "leading-4",
                  "tracking-[0px]",
                  "text-left",
                  "text-app-black",
                  "cursor-pointer",
                  "hover:bg-[#F4672B]",
                  "hover:text-white"
                )}
              >
                {t(each.key)}
              </li>
            </Link>
          ))}
        </ul>
      </div>
      <div className="absolute z-[20] right-2 bottom-full translate-y-[1px]">
        <svg
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6 0L11.1962 7.5H0.803848L6 0Z" fill="white" />
        </svg>
      </div>
    </div>
  );
};

type Props = {
  className?: React.HTMLProps<HTMLElement>["className"];
};

const NavBar = ({ className }: Props) => {
  const { t, i18n } = useTranslation("navBar");
  const { currentUser } = useContext(UserViewModelContext);
  const [openBookMenu, setOpenBookMenu] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const bookingDropDownRef = useRef<HTMLDivElement>(null);
  const pathname = location.pathname;

  const ITEM_LIST = useMemo(() => {
    return currentUser
      ? [
          { key: "sportPass", url: ROUTE_BASE.SPORT_PASS },
          { key: "news", url: ROUTE_BASE.NEWS },
          { key: "events", url: ROUTE_BASE.EVENT },
          { key: "discover", url: ROUTE_BASE.DISCOVER },
          { key: "packageOffer", url: ROUTE_BASE.PACKAGE_OFFER },
        ]
      : [
          { key: "news", url: ROUTE_BASE.NEWS },
          { key: "events", url: ROUTE_BASE.EVENT },
          { key: "discover", url: ROUTE_BASE.DISCOVER },
        ];
  }, [currentUser]);

  const handleChangeLanguage = (lang: string) => {
    localStorage.setItem("language", lang);
    navigate(`/${lang}/${pathname.split("/").slice(2).join("/")}`);
  };

  const handleCloseBookMenu = () => {
    setOpenBookMenu(false);
  };

  const handleClickBookMenu = (route: string) => {
    setOpenBookMenu(false);
    navigate(route);
  };

  const closeOpenMenus = (e: any) => {
    if (
      openBookMenu &&
      !bookingDropDownRef.current?.contains(e.target as Node)
    ) {
      setOpenBookMenu(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);
  useEffect(() => {
    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  }, []);

  return (
    <div className={className}>
      <FlexBox style={{ justifyContent: "flex-end" }}>
        {/* <Weather />
        <Space width="20px" /> */}
        <FlexBox>
          <Typography
            color={i18n.language === "en" ? theme.black : theme.white}
            style={{ cursor: "pointer" }}
            onClick={() => handleChangeLanguage("en")}
          >
            EN
          </Typography>
          <Typography color={theme.white} style={{ margin: "0 5px" }}>
            |
          </Typography>
          <Typography
            color={i18n.language === "zh" ? theme.black : theme.white}
            style={{ cursor: "pointer" }}
            onClick={() => handleChangeLanguage("zh")}
          >
            繁
          </Typography>
        </FlexBox>
        {/* <Space width="20px" />
        <Link to={ROUTE_BASE.HEADLINE_NEWS}>
          <Image src={IconNews} />
        </Link>

        {currentUser && (
          <>
            <Space width="20px" />
            <Link to={ROUTE_BASE.NOTIFICATION}>
              <Image src={IconNotification} />
            </Link>
          </>
        )} */}
        {/* <Space width="20px" />
        {currentUser == null ? (
          <ProfileImage
            style={{ cursor: "pointer" }}
            src={IconGuest}
            onClick={() => navigate(ROUTE_BASE.PROFILE)}
          />
        ) : (
          <ProfileImage
            style={{ cursor: "pointer" }}
            src={
              currentUser?.profile_pic_ref ?? "https://placekitten.com/200/300"
            }
            onClick={() =>
              navigate(`${i18n.language}/${ROUTE_BASE.PROFILE}/edit`)
            }
          />
        )} */}
      </FlexBox>
      {/* <Space size="10px" /> */}
      <FlexBox className="space-x-[20px] justify-end">
        {/* {ITEM_LIST.map((item, idx) => {
          return (
            <Link to={`../${item.url}`} key={item.key}>
              <Item>
                <Typography color={theme.white}>
                  {t(`topBar.${item.key}`)}
                </Typography>
              </Item>
            </Link>
          );
        })} */}

        {/* {currentUser && (
          <div className="relative">
            <Item
              ref={bookingDropDownRef}
              className="flex items-center gap-[5px]"
              onClick={() => setOpenBookMenu((prev) => !prev)}
            >
              <p className={cn(openBookMenu ? "text-black" : "text-white")}>
                {t(`topBar.booking`)}
              </p>
              <svg
                width="11"
                height="7"
                viewBox="0 0 11 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L5.09082 5.09082L9.18164 1"
                  stroke={openBookMenu ? "black" : "white"}
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
              <div
                className={cn(
                  "absolute right-0 top-full translate-y-2",
                  openBookMenu ? "block" : "hidden"
                )}
              >
                <BookingDropdown handleClickBookMenu={handleClickBookMenu} />
              </div>
            </Item>
          </div>
        )} */}
      </FlexBox>
    </div>
  );
};

export default NavBar;
