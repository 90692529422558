import dayjs from "dayjs";
import { authedInstance, instance } from ".";
import { FacilityBookingOrder } from "../models/FacilityBookingOrder";
import { getDayOfWeekSlug } from "../utils/DateHelper";
import { getLocalisedString } from "../utils/stringHelper";

export async function holdTimeslot(payload) {
    console.log("Hold timeslot: ", payload);
    const res = await authedInstance.post("/v1/common/booking/hold", payload);
    console.log("Hold timeslot: ", res.data);
    return res.data;
}

export async function createBooking(payload) {
    console.log("Create Booking: ", payload);
    const res = await authedInstance.post("/v1/app/booking", payload);
    console.log("Create Booking: ", res.data);
    return res.data;
}

export async function listMyFacilityBooking(payload) {
    try {
        console.log("List My Facility Booking", payload);

        if (payload != null) {
            const res = await authedInstance.get(
                `/v1/app/facility/booking?${new URLSearchParams(
                    payload
                ).toString()}`
            );

            console.log("List Booking res: ", res.data);
            return res.data.result.map(
                (bookingOrder) => new FacilityBookingOrder(bookingOrder)
            );
        } else {
            const res = await authedInstance.get("/v1/app/facility/booking");

            console.log("List Booking res: ", res.data);
            return res.data.result.map(
                (bookingOrder) => new FacilityBookingOrder(bookingOrder)
            );
        }
    } catch (err) {
        throw err;
    }
}

export async function listConfirmedFacilityBooking() {
    try {
        // Upcomming Booking: Confirmed + Reserved Date >= today
        const res = await listMyFacilityBooking({
            listByTime: "UPCOMING", // Upcoming Booking
            sort: "expiryTime", // sort by expiry time
            order: "asc",
        });
        return res;
    } catch (err) {
        throw err;
    }
}

export async function listPastFacilityBooking({ status }) {
    try {
        // Upcomming Booking: Confirmed / Cancelled + Reserved Date < today
        const res = await listMyFacilityBooking({
            status,
            listByTime: "PAST", // Past Booking
            sort: "expiryTime", // sort by expiry time
            order: "desc",
        });
        return res;
    } catch (err) {
        throw err;
    }
}

export async function getMyFacilityBookingDetail({ orderId }) {
    try {
        const res = await authedInstance.get(
            `/v1/app/facility/booking/${orderId}`
        );
        return new FacilityBookingOrder(res.data.result);
    } catch (err) {
        throw err;
    }
}

export async function updateFacilityBookingGuests({ orderId, guestlist }) {
    try {
        const res = await authedInstance.put(
            `/v1/app/facility/booking/${orderId}/guestlist`,
            {
                guestlist: guestlist,
            }
        );
        return res;
    } catch (err) {
        let errorMsg = getLocalisedString(
            err?.response?.data?.error?.localizedMessage?.en,
            err?.response?.data?.error?.localizedMessage?.zh
        );
        throw new Error(errorMsg);
    }
}

export async function cancelFacilityBooking({ orderId }) {
    try {
        const res = await authedInstance.post(
            `/v1/app/facility/booking/${orderId}/cancel`
        );
        console.log("Cancel booking: ", res);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function listBooking({ past = false }) {
    console.log("List Booking: ", past);
    const res = await authedInstance.post(
        `/v1/app/bookings?past=${past ?? false}`
    );
    console.log("List Booking: ", res.data);
    return res.data;
}

export async function getEventBookingDetail({ bookingId }) {
    console.log("Event Booking Id: ", bookingId);
    const res = await authedInstance.get(`/v1/app/event-booking/${bookingId}`);
    return res.data;
}

export async function removeEventGuest({ orderId, email }) {
    try {
        console.log("check 6 ", { email });
        const res = await authedInstance.delete(
            `/v1/app/event-booking/${orderId}/invite`,
            {
                data: { email },
            }
        );
        return res;
    } catch (err) {
        let errorMsg = getLocalisedString(
            err?.response?.data?.error?.localizedMessage?.en,
            err?.response?.data?.error?.localizedMessage?.zh
        );
        throw new Error(errorMsg);
    }
}

export async function inviteEventGuest({ orderId, guestlist }) {
    try {
        const res = await authedInstance.post(
            `/v1/app/event-booking/${orderId}/invite`,
            {
                data: guestlist,
            }
        );
        return res;
    } catch (err) {
        let errorMsg = getLocalisedString(
            err?.response?.data?.error?.localizedMessage?.en,
            err?.response?.data?.error?.localizedMessage?.zh
        );
        throw new Error(errorMsg);
    }
}

export async function getEnrollmentDetail({ bookingId }) {
    console.log("Enrollment Booking Id: ", bookingId);
    const res = await authedInstance.get(
        `v1/app/monthly-programme/programme/enroll/${bookingId}`
    );
    return res.data;
}
