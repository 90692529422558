import React from "react";
import styled from "styled-components";
import { WindowSize, size } from "../../../utils/responsive";

const Card = styled.div`
  position: relative;
  width: calc(100dvw - 42px);
  background: var(--palette-ss-white, #fff);
  border-radius: 30px;

  @media (min-width: ${size.screen1024}px) {
    height: 100dvh;
  }
`;

type CardProps = {
  maxWidth: string | number;
  maxHeight: string | number;
};

type Props = CardProps & {
  children: React.ReactElement | React.ReactElement[];
};

const ModalCard = (props: Props) => {
  const windowsSize = WindowSize();
  return (
    <Card
      style={{
        maxWidth: props.maxWidth,
        maxHeight:
          windowsSize === "screen1024" ? props.maxHeight : "fit-content",
      }}
    >
      {props.children}
    </Card>
  );
};

export default ModalCard;
