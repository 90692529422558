interface spaceProps {
  size?: string;
  width?: string;
}

export default function Space({
  size = "10px",
  width = "0px",
  ...style
}: spaceProps) {
  return (
    <div style={{ ...style, height: size, width: width, flexShrink: 0 }}></div>
  );
}
