import React, { useState, useEffect, useContext, useRef } from "react";
import { BrowserRouter, useLocation, useSearchParams } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import cn from "classnames";
import Route from "./Route";
import { IGlobalProviderContext, IUserViewModelContext } from "./@types/common";
import { GlobalContextStore } from "common/context/providers/globalProvider";
import {
  UserViewModelContext,
  UserViewModelProvider,
} from "./common/viewModels/UserViewModel";
import { navToLogin } from "./navigators/Router";
import BottomBar from "./components/BottomBar";
import GlobalProvider from "common/context/providers/globalProvider";
import ConfirmModalProvider, {
  confirmModalContext,
} from "contexts/ConfirmModalProvider";
import theme from "utils/theme";
import { useTranslation } from "react-i18next";
import NavBar from "components/NavBar";
import { initRefreshInterceptor } from "common/network";
import GlobalLoading from "components/common/GlobalLoading";

function App() {
  const location = useLocation();
  const { t } = useTranslation("common");
  const [disableNavbar, setDisableNavbar] = useState(false);

  const { openModal } = useContext(confirmModalContext);
  const { globalState, globalDispatch } = useContext(
    GlobalContextStore as any
  ) as IGlobalProviderContext;
  const { logout, currentUser, updateCurrentUserToken } = useContext(
    UserViewModelContext
  ) as IUserViewModelContext;
  const [searchParams, setSearchParams] = useSearchParams({
    hideMenu: "",
  });
  const hideMenu = searchParams.get("hideMenu"); //hide nav for app use
  const logoutFuncRef = useRef(logout);
  const updateTokenFuncRef = useRef(updateCurrentUserToken);

  useEffect(() => {
    if (
      location?.pathname?.includes("auth") ||
      location?.pathname?.includes("register") ||
      hideMenu === "true"
    )
      setDisableNavbar(true);
    else setDisableNavbar(false);
  }, [location]);

  useEffect(() => {
    if (globalState.globalError != null) {
      openModal({
        title:
          globalState.globalError.message ?? t("error.internalServerError"),
      });
    }
    // Ignore openModal to be trigger on openModal
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.globalError]);

  useEffect(() => {
    initRefreshInterceptor(
      () => {
        console.log("Interceptor Refresh failed, logging out");
        if (logoutFuncRef.current) logoutFuncRef.current();
      },
      (accessToken, refreshToken) => {
        if (updateTokenFuncRef.current)
          updateTokenFuncRef.current(accessToken, refreshToken);

        return {};
      }
    );
  }, []);

  // The js DOM global initRefreshInterceptor() will also create the callback function in memory when init.
  // Use useRef to make sure callback is calling the logout with latest context
  useEffect(() => {
    logoutFuncRef.current = () => {
      logout(globalDispatch, t("login:session_expired"));
    };
    updateTokenFuncRef.current = updateCurrentUserToken;
  }, [logout, currentUser]);

  return (
    <div
      className={cn(
        "relative min-h-screen overflow-hidden bg-app-cyan",
        disableNavbar ? "" : "pb-[330px] md:pb-[75px]"
      )}
    >
      {!location?.pathname?.includes("auth") && (
        <GlobalLoading loading={globalState.globalLoading} />
      )}
      {!disableNavbar && <NavBar />}
      <div className="min-h-[calc(100vh-283px)]">
        <Route />
      </div>
      {!disableNavbar && <BottomBar />}
    </div>
  );
}
const AppWrapper = () => {
  return (
    <GlobalProvider>
      <ConfirmModalProvider>
        <SnackbarProvider maxSnack={3}>
          <UserViewModelProvider onLogout={navToLogin}>
            <BrowserRouter>
              <App />
              <div id="ss-modal-portal" />
            </BrowserRouter>
          </UserViewModelProvider>
        </SnackbarProvider>
      </ConfirmModalProvider>
    </GlobalProvider>
  );
};

export default AppWrapper;
