import config from "../../config";
import axios from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { useContext } from "react";
import { UserViewModelContext, UserViewModelProvider } from "../viewModels/UserViewModel";
import { getDefaults, saveDefaults, userDefaultKeys } from "../../utils/UserDefaultHelper";
import { refreshToken } from "./auth";
// const axios = require("axios");

export const instance = axios.create({
  baseURL: config.API_ROOT,
  timeout: config.API_TIMEOUT,
});

export const authedInstance = axios.create({
  baseURL: config.API_ROOT,
  timeout: config.API_TIMEOUT,
});

export const generalInstance = axios.create({
  timeout: config.API_TIMEOUT
})

const genericErrorHandler = (err) => {
  console.log("err", err.response?.code, err.response?.data);
  return Promise.reject(err);
};

const refreshAuthLogic = async (failedRequest, onFailed, onRefreshed = null) => {
  const currentUser = await getDefaults(userDefaultKeys.CURRENT_USER)
  // console.log('refreshAuthLogic, log current user', currentUser);
  try {
    const res = await refreshToken({refresh_token: currentUser?.refresh_token})
    authedInstance.defaults.headers.Authorization =
          "Bearer " + res.access_token;
    failedRequest.response.config.headers[
      'Authorization'
    ] = `Bearer ${res.access_token}`;
    await saveDefaults(userDefaultKeys.CURRENT_USER, {...currentUser, refresh_token: res.refresh_token, access_token: res.access_token})

    if(onRefreshed) onRefreshed(res.access_token, res.refresh_token)
  } catch (err) {
    // console.log('refreshAuthLogic err ',err)
    onFailed()
  }
  return Promise.resolve();
};

/**
 * NOTE: When a function is defined, it captures its surrounding scope at the time of creation. 
 * If the value of the variable outside the function changes, 
 * the captured value inside the function remains the same unless the function is redefined.
 * Please aware the change happen outside will different to function inside when the callback get called.
 * @param {*} onFailed Callback when fail to refresh token. 
 * @param {(access_token, refresh_token) => {}} onRefreshed Callback when token refrehsed. 
 */
export const initRefreshInterceptor = (onFailed, onRefreshed = null) => {
  createAuthRefreshInterceptor(authedInstance, failedRequest=>refreshAuthLogic(failedRequest, onFailed, onRefreshed), {
    skipWhileRefreshing: false,
    statusCodes: [401]
  });
}

instance.interceptors.response.use((res) => {
  console.log(res);
  return res;
}, genericErrorHandler);

authedInstance.interceptors.response.use((res) => {
  console.log(res);
  return res;
}, genericErrorHandler);

generalInstance.interceptors.response.use((res) => {
  console.log(res);
  return res;
}, genericErrorHandler);
