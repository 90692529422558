import {
  APPEAR_GLOBAL_ERROR,
  DISAPPEAR_GLOBAL_ERROR,
  APPEAR_GLOBAL_LOADING,
  DISAPPEAR_GLOBAL_LOADING,
} from "../actions/globalAction";

export const appearGlobalError = (dispatch, message) => {
  dispatch({ type: APPEAR_GLOBAL_ERROR, message });
};

export const disappearGlobalError = (dispatch) => {
  dispatch({ type: DISAPPEAR_GLOBAL_ERROR });
};

export const appearGlobalLoading = (dispatch) => {
  dispatch({ type: APPEAR_GLOBAL_LOADING });
};

export const disappearGlobalLoading = (dispatch) => {
  dispatch({ type: DISAPPEAR_GLOBAL_LOADING });
};
