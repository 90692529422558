import { createContext, useRef, useState } from "react";
import {
  login as loginAPI,
  register as registerAPI,
  forgetPassword as forgetPasswordAPI,
  resetPassword as resetPasswordAPI,
} from "../network/auth";
import { useEffect } from "react";
import {
  getDefaults,
  resetDefaults,
  saveDefaults,
  userDefaultKeys,
} from "../../utils/UserDefaultHelper";
import { getUser as getUserAPI } from "../network/user";
import { navToLogin, routeCodes } from "../../navigators/Router";
import { listBooking as listBookingAPI } from "../network/booking";
import { authedInstance } from "../network";
import { appearGlobalError, appearGlobalLoading, disappearGlobalLoading } from "../context/requests/globalRequest";
import dayjs from "dayjs";
import { getDateIsBefore } from "../utils/DateHelper";
import { useTranslation } from "react-i18next";
import config from "../../config";
import { unregisterDeviceToken } from "../network/notification";

export const UserViewModelContext = createContext();

export const UserViewModelProvider = ({ children, onLogout }) => {
  const {t} = useTranslation('login')
  const [currentUser, setCurrentUser] = useState(null);
  const [cachedUsers, setCachedUsers] = useState([]);
  const [biometricList, setBiometricList] = useState([]);
  const [isRetriving, setIsRetriving] = useState(true);
  const [userListLoading, setIsUserListLoading] = useState(true);
  const [currentUserLoading, setIsCurrentUserLoading] = useState(true);
  const [myBookings, setMyBookings] = useState([]);
  const [isCurrentPassExpired, setIsCurrentPassExpired] = useState(true);
  const [isSwitchingUser, setIsSwitchingUser] = useState(false);
  const [showBiometricNotice, setShowBiometricNotice] = useState(false);
  const [showBiometricResult, setShowBiometricResult] = useState(false);
  const [biometricRegisterTitle, setBiometricRegisterTitle] = useState(null);
  const [biometricRegisterMsg, setBiometricRegisterMsg] = useState(null);
  const [tmpCred, setTmpCred] = useState(null);
  const prevUser = useRef(null);

  useEffect(() => {
    getDefaults(userDefaultKeys.BIOMETRIC_LIST).then((list)=>{
      setBiometricList(list ?? [])
    })
    getDefaults(userDefaultKeys.CURRENT_USER).then((user) => {
      console.log('retrived user from local storage ',user)
      if (user) {
        authedInstance.defaults.headers.Authorization =
          "Bearer " + user?.access_token;
        setCurrentUser(user);
      } else {
        setIsCurrentUserLoading(false)
      }
      getDefaults(userDefaultKeys.CACHED_USERS)
        .then((userList) => {
          setCachedUsers(userList?.filter(item=>item) ?? []);
        })
        .finally(() => setIsUserListLoading(false));
    });
  }, []);

  useEffect(() => {
    console.log("biometricList: ", biometricList);
  }, [biometricList]);

  useEffect(() => {
    console.log("cached users: ", cachedUsers);
  }, [cachedUsers]);

  useEffect(() => {
    console.log("useEffect current user: ", currentUser);
    checkIsSportPassExpired();
    if (
      currentUser &&
      prevUser.current &&
      currentUser?.memberId != prevUser.current?.memberId &&
      isSwitchingUser
    ) {
      //switched from other acc
      console.log("is switching from other acc");
      refreshCurrentUser();
    }
    if (currentUser) {
      let index = biometricList.findIndex(
        (el) => el?.email && el.email?.toLowerCase() == currentUser?.email?.toLowerCase()
      );
      if (
        index >= 0 &&
        (biometricList[index].profile_pic_ref != currentUser.profile_pic_ref ||
          biometricList[index].username != currentUser.username)
      ) {
        let newList = [...biometricList];
        newList[index].profile_pic_ref = currentUser.profile_pic_ref;
        newList[index].username = currentUser.username;
        saveDefaults(userDefaultKeys.BIOMETRIC_LIST, newList);
        setBiometricList(newList);
      }
    }
    prevUser.current = currentUser;
  }, [currentUser]);

  useEffect(()=>{
    setIsRetriving(currentUserLoading && userListLoading)
  },[currentUserLoading, userListLoading])

  const updateSavedUser = async (user, remove = false) => {
    const isDifferentUser = prevUser.current?.memberId != user?.memberId
    const updatedUser = isDifferentUser || !user ? user : {
      access_token: prevUser.current?.access_token,
      refresh_token: prevUser.current?.refresh_token,
      ...user,
    };
    console.log('updateSavedUser check 1 ',cachedUsers, isDifferentUser, updatedUser)
    if (cachedUsers.some((savedUser) => savedUser.memberId == updatedUser?.memberId)) {   //cached user included
      const newUserList = remove
        ? cachedUsers.filter((savedUser) =>
          savedUser._id != user._id
        )
        : cachedUsers.map((savedUser) =>
          savedUser._id == user._id ? updatedUser : savedUser
        )
      await saveDefaults(
        userDefaultKeys.CACHED_USERS,
        newUserList
      );
      setCachedUsers(newUserList)
      //console.log('updateSavedUser check 2 ', newUserList)
    } else if (!remove) {   //add new user
      if (updatedUser) {
        await saveDefaults(userDefaultKeys.CACHED_USERS, [...cachedUsers, updatedUser]);
        setCachedUsers([...cachedUsers, updatedUser])
      }
    }
    await saveDefaults(userDefaultKeys.CURRENT_USER, remove ? null : updatedUser);
    setCurrentUser(remove ? null : updatedUser);
    return(updatedUser)
  }

  const guestLogin = async () => {
    authedInstance.defaults.headers.Authorization = undefined
    await updateSavedUser(null)
  }

  const login = async ({
    dispatch,
    email,
    password,
    lang = "en",
    isUsingAuth = false,
  }) => { 
    try {
      const payload = {
        accountType: "email",
        email,
        password,
        lang,
      };
      setIsSwitchingUser(false)

      appearGlobalLoading(dispatch);
      const res = await loginAPI(payload);
      if (res.success) {
        const { access_token, refresh_token } = res;
        const userRes = await getUserAPI();
        if (userRes.success && userRes.user) {
          if(biometricList.some(el=>el.email?.toLowerCase() == email?.toLowerCase())){

          } else {
            setTmpCred({email, ath: password, profile_pic_ref: userRes.user?.profile_pic_ref, username: userRes.user?.username})
            setShowBiometricNotice(true)
          }
          const user = {
            ...userRes.user,
            access_token,
            refresh_token,
          };
          await updateSavedUser(user)

          let ind = biometricList.findIndex(el=>el?.email && el.email?.toLowerCase() != email?.toLowerCase() && el.allow)
          if (ind >= 0)
            updateBiometricSetting(true, {ath: password})
          disappearGlobalLoading(dispatch);
          return {success: true}
        }
      }
    } catch (e) {
      console.log("Login Exception", e);
      disappearGlobalLoading(dispatch);
      if (isUsingAuth && e?.response?.data?.msg == 'Incorrect email / Incorrect password') {
        appearGlobalError(dispatch, `${e?.response?.data?.msg ? e?.response?.data?.msg + '\n' : ''}${t('removeBiometric')}`);
        removeBiometricSetting({email})
      } else {
        appearGlobalError(dispatch, e?.response?.data?.msg);
      }
    }
    return {success: false}
  };

  const logout = async (dispatch, message = null) => {
    console.log("logout");
    if (message) appearGlobalError(dispatch, message);
    if (config.IS_APP) await unregisterDeviceToken();
    await updateSavedUser(currentUser, true);
    // resetDefaults()
    // setCachedUsers([])
    if (onLogout) onLogout();
  };

  const register = async ({userInfo, dispatch}) => {
    try {
      const payload = {
        preferredLanguage: "en",
        isVip: false,
        accountType: "email",
        ...userInfo,
      };
      appearGlobalLoading(dispatch);
      const res = await registerAPI(payload);
      disappearGlobalLoading(dispatch);
      return res;
    } catch (e) {
      disappearGlobalLoading(dispatch);
      appearGlobalError(dispatch, e?.response?.data?.msg);
      return { success: false };
    }
  };

  const forgetPassword = async ({ email, dispatch }) => {
    try {
      const payload = {
        email,
        accountType: "email",
        recaptcha_token: "123",
      };
      appearGlobalLoading(dispatch);
      const res = await forgetPasswordAPI(payload);
      disappearGlobalLoading(dispatch);
      return res;
    } catch (e) {
      disappearGlobalLoading(dispatch);
      appearGlobalError(dispatch, e?.response?.data?.msg?.message);
      return { success: false };
    }
  };

  const resetPassword = async ({ password, token, dispatch }) => {
    try {
      const payload = {
        new_password: password,
        token: token,
        action: "set_app_pw",
      };
      appearGlobalLoading(dispatch);
      const res = await resetPasswordAPI(payload);
      disappearGlobalLoading(dispatch);
      return res;
    } catch (e) {
      disappearGlobalLoading(dispatch);
      appearGlobalError(dispatch, e?.response?.data?.msg);
      return { success: false };
    }
  };

  const checkIsSportPassExpired = () => {
    var isExpired = true;
    let expiryDate = dayjs(currentUser?.sportpass?.expiry_date);
    if (!!currentUser && !!expiryDate) {
      isExpired = !getDateIsBefore(dayjs(Date.now()), expiryDate);
    }
    setIsCurrentPassExpired(isExpired);
  };

  const getMyBookings = async (past = false, dispatch) => {
    try {
      if (!currentUser) return [];
      const res = await listBookingAPI({ past });
      return res.success;
    } catch (e) {
      appearGlobalError(dispatch, e?.response?.data?.msg);
      return false;
    }
  };

  const refreshCurrentUser = async () => {
    try {
      const userRes = await getUserAPI();
      if (userRes.success && userRes.user) {
        const user = userRes.user
        await updateSavedUser(user);
        return user;
      }
    } catch {
      return false;
    }
  };

  const switchUser = async ({memberId, callback = ()=>{}, dispatch}) => {
    try{
      setIsSwitchingUser(true)
      console.log('switch to user ',memberId)
      appearGlobalLoading(dispatch)
      const user = await getDefaults(userDefaultKeys.CURRENT_USER)
      // Note: user can null if after user logout then switch
      if(user) {
        await updateSavedUser(user)
        console.log('saved prev user')
      }
      const newUser = cachedUsers?.find(el=>el?.memberId == memberId)
      authedInstance.defaults.headers.Authorization =
        "Bearer " + newUser?.access_token;
      const updatedUser = await updateSavedUser(newUser)
      // await refreshCurrentUser()
      disappearGlobalLoading(dispatch)
      callback()
    }catch (e) {
      console.log('switchUser err ',e)
      disappearGlobalLoading(dispatch)
    }
  }

  // NOTE: userDefaultKeys.CURRENT_USER should already updated. when this function called, the user default still not yet overwrite by updateSavedUser()
  const updateCurrentUserToken = async (access_token, refresh_token) => {
    const currentUser = await getDefaults(userDefaultKeys.CURRENT_USER)
    // console.log("updateCurrentUserToken currentUser, refresh_token", currentUser, refresh_token);
    
    // update current user default
    const updatedUser = {...currentUser, access_token, refresh_token} 
    await saveDefaults(userDefaultKeys.CURRENT_USER, updatedUser)

    // update cached users default
    const newUserList = cachedUsers.map((savedUser) =>
        savedUser._id === updatedUser._id ? updatedUser : savedUser
      )
    await saveDefaults(userDefaultKeys.CACHED_USERS, newUserList);

    // update context provider state
    setCachedUsers(newUserList)
    setCurrentUser(updatedUser);
    // console.log("updateCurrentUserToken updatedUser", updatedUser);
  }

  const updateBiometricSetting = async (allow, info) => {
    if (showBiometricNotice) setShowBiometricNotice(false)
    let newList = [...biometricList]
    const index = biometricList.findIndex(el=>el?.email && el.email?.toLowerCase() == currentUser?.email?.toLowerCase())
    if ( index >= 0) {    //existing entry
      if (!allow) {
        let {ath, ...newCred} = newList[index]
        newList[index] = {...newCred, allow}
      } else {
        newList[index] = {...newList[index], allow, ...info}
      }
    } else {    //new entry
      if (!allow) {
        let {ath, ...newCred} = tmpCred
        newList = newList.concat({...newCred, allow})
      } else {
        newList=newList.concat({...tmpCred, allow})
      }
    }
    await saveDefaults(userDefaultKeys.BIOMETRIC_LIST, newList)
    setBiometricList(newList)
  }

  const removeBiometricSetting = async (info) => {
    if (showBiometricNotice) setShowBiometricNotice(false)
    let newList = biometricList.filter(el=>el?.email && el.email?.toLowerCase() != info?.email?.toLowerCase())
    await saveDefaults(userDefaultKeys.BIOMETRIC_LIST, newList)
    setBiometricList(newList)
  }

  const showBiometricRegisterResult = (title, message) => {
    setBiometricRegisterTitle(title)
    setBiometricRegisterMsg(message)
    setShowBiometricResult(true)
  }
  const hideBiometricRegisterResult = () => {
    setShowBiometricResult(false)
    setBiometricRegisterTitle(null)
    setBiometricRegisterMsg(null)
  }

  const currentAllowBiometricSetting = biometricList.find(el=>el?.email && currentUser?.email?.toLowerCase() == el.email?.toLowerCase())?.allow ?? false

  return (
    <UserViewModelContext.Provider
      value={{
        currentUser,
        cachedUsers,
        isRetriving,
        isCurrentPassExpired,
        myBookings,
        showBiometricNotice,
        biometricList,
        currentAllowBiometricSetting,
        showBiometricResult,
        biometricRegisterTitle,
        biometricRegisterMsg,
        showBiometricRegisterResult,
        hideBiometricRegisterResult,
        guestLogin,
        login,
        logout,
        register,
        forgetPassword,
        resetPassword,
        getMyBookings,
        refreshCurrentUser,
        switchUser,
        setCachedUsers,
        updateCurrentUserToken,
        setShowBiometricNotice,
        updateBiometricSetting,
      }}
    >
      {children}
    </UserViewModelContext.Provider>
  );
};
