import styled from "styled-components";
import { Button } from "@mui/material";
import theme from "../../utils/theme";
import React from "react";

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  children: React.ReactNode;
  fullWidth?: boolean;
  width?: string;
  bgColor?: string;
}

const CustomButton = styled(Button)<ButtonProps>`
  && {
    color: var(--palette-ss-white, #fff);
    background-color: ${(props) => props.bgColor ?? theme.blue};
    border-radius: 5px;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-transform: none;
    width: ${(props) => (props.fullWidth ? "100%" : props?.width || "auto")};
    height: ${(props) => (props.size ? props.size : "45px")};

    &:hover {
      background-color: ${(props) => props.bgColor ?? theme.blue};
    }

    &.Mui-disabled {
      background-color: #8c8c8c;
      color: var(--palette-ss-white, #fff);
    }
  }
`;

const GeneralButton = ({ children, ...remainProps }: ButtonProps) => {
  return <CustomButton {...remainProps}>{children}</CustomButton>;
};

export default GeneralButton;
