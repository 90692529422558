import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useNavigate,
  useParams,
  Outlet,
  useSearchParams,
} from "react-router-dom";

const SUPPORT_LANG = ["en", "zh"];

const LanguagePath = () => {
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const pathname = location.pathname;

  useEffect(() => {
    if ((lang && i18n.resolvedLanguage !== lang) || i18n.language !== lang) {
      const firstParam = pathname.split("/");
      const curPath = SUPPORT_LANG.includes(firstParam[1])
        ? "/" + pathname.split("/").slice(2).join("/")
        : pathname;

      if (i18n.language !== lang && SUPPORT_LANG.includes(lang as string)) {
        localStorage.setItem("language", lang as string);
        i18n.changeLanguage(lang);
      }

      navigate(
        {
          pathname: "/" + i18n.resolvedLanguage + curPath,
          search: searchParams.toString(),
        },
        { replace: true }
      );
    }
  }, [lang, i18n.language]);

  return <Outlet />;
};

export default LanguagePath;
