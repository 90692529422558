import { EncryptStorage } from "encrypt-storage";

const encryptStorage = new EncryptStorage("secret-key-value");

export const userDefaultKeys = {
  CURRENT_USER: "CURRENT_USER",
  CACHED_USERS: "CACHED_USER",
};

export const saveDefaults = async (key: string, value: object) => {
  try {
    if (value != null && value != undefined)
      await encryptStorage.setItem(key, JSON.stringify(value));
    else await encryptStorage.removeItem(key);
  } catch (error) {
    console.error("Error saving data to user defaults:", error);
  }
};

export const getDefaults = async (key: string) => {
  try {
    const value = await encryptStorage.getItem(key);

    return value;
  } catch (error) {
    // There was an error on the native side
    console.log("error", error);
  }
};

export const resetDefaults = async () => {
  try {
    await encryptStorage.clear();
    // Congrats! You've just cleared the device storage!
  } catch (error) {
    // There was an error on the native side
  }
};

// export const userDefaultKeys = {
//   CURRENT_USER: "CURRENT_USER",
//   CACHED_USERS: "CACHED_USER",
// };

// export const saveDefaults = async (key: string, value: object) => {
//   try {
//     if (value != null && value != undefined)
//       await localStorage.setItem(key, JSON.stringify(value));
//     else await localStorage.removeItem(key);
//   } catch (error) {
//     console.error("Error saving data to user defaults:", error);
//   }
// };

// export const getDefaults = async (key: string) => {
//   try {
//     const value: any = await localStorage.getItem(key);

//     return JSON.parse(value);
//   } catch (error) {
//     // There was an error on the native side
//   }
// };

// export const resetDefaults = async () => {
//   try {
//     await localStorage.clear();
//     // Congrats! You've just cleared the device storage!
//   } catch (error) {
//     // There was an error on the native side
//   }
// };
