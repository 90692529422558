import { forwardRef } from "react";
import Styled from "./Styled";
import Typography from "components/common/Typography";
import theme from "utils/theme";

interface TableProps extends React.HTMLProps<HTMLDivElement> {
  title: string;
  children: React.ReactNode;
}

const ColorBannerCard = forwardRef<HTMLDivElement, TableProps>(
  (props: TableProps, ref) => {
    const { title, children, ...remainsHTMLProps } = props;
    return (
      <div {...remainsHTMLProps} ref={ref}>
        <Styled.TableTitle>
          <Typography fontSize="20px" fontWeight="700" color={theme.blue}>
            {title}
          </Typography>
        </Styled.TableTitle>
        <Styled.TableCard>{children}</Styled.TableCard>
      </div>
    );
  }
);

export default ColorBannerCard;
