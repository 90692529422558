import React, { Children } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import _ from "lodash";
import { WindowSize } from "utils/responsive";
import styled from "styled-components";
import theme from "utils/theme";

const settings = {
  //centerMode: true,
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 860,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

type CarouselProps = {
  item?: string[];
} & React.HTMLAttributes<HTMLElement>;
const Carousel = ({ item, children }: CarouselProps) => {
  return (
    <StyledSlider {...settings}>
      {item &&
        _.map(item, (slide) => (
          <div className="img-box h-full">
            <img src={slide} className="m-auto" />
          </div>
        ))}
      {children}
    </StyledSlider>
  );
};

const StyledSlider = styled(Slider)`
  .slick-dots {
    bottom: 5%;
  }

  .slick-dots li button:before {
    color: #fff;
    font-size: 15px;
  }

  .slick-dots li.slick-active button:before {
    color: ${theme.orange};
  }

  .slick-slide img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export default Carousel;
