// Resources
import commonEn from "./en/common.json";
import loginEn from "./en/login.json";
import registerEn from "./en/register.json";
import passEn from "./en/pass.json";
import homeEn from "./en/home.json";
import eventEn from "./en/event.json";
import commonZh from "./zh/common.json";
import loginZh from "./zh/login.json";
import registerZh from "./zh/register.json";
import passZh from "./zh/pass.json";
import homeZh from "./zh/home.json";
import eventZh from "./zh/event.json";
import profileEn from "./en/profile.json";
import profileZh from "./zh/profile.json";
import coachingZh from "./zh/coaching.json";
import coachingEn from "./en/coaching.json";
import bookingEn from "./en/booking.json";
import bookingZh from "./zh/booking.json";
import myBookingEn from "./en/myBooking.json";
import myBookingZh from "./zh/myBooking.json";

import discoverEn from "./en/discover.json";
import discoverZh from "./zh/discover.json";
import bundleEn from "./en/bundle.json";
import bundleZh from "./zh/bundle.json";
import notificationEn from "./en/notification.json";
import notificationZh from "./zh/notification.json";

import monthlyProgramEn from "./en/monthlyProgram.json";
import monthlyProgramZh from "./zh/monthlyProgram.json";
import voucherEn from "./en/voucher.json";
import voucherZh from "./zh/voucher.json";

import navBarEn from "./en/navBar.json";
import navBarZh from "./zh/navBar.json";

import newEn from "./en/new.json";
import newZh from "./zh/new.json";

import contactUsEn from "./en/contactUs.json";
import contactUsZh from "./zh/contactUs.json";

export const resources = {
  en: {
    booking: bookingEn,
    common: commonEn,
    login: loginEn,
    register: registerEn,
    sportpass: passEn,
    home: homeEn,
    event: eventEn,
    coaching: coachingEn,
    discover: discoverEn,
    monthlyProgram: monthlyProgramEn,
    myBooking: myBookingEn,
    profile: profileEn,
    bundle: bundleEn,
    navBar: navBarEn,
    notification: notificationEn,
    new: newEn,
    voucher: voucherEn,
    contactUs: contactUsEn
  },
  zh: {
    booking: bookingZh,
    common: commonZh,
    login: loginZh,
    register: registerZh,
    sportpass: passZh,
    home: homeZh,
    event: eventZh,
    coaching: coachingZh,
    discover: discoverZh,
    monthlyProgram: monthlyProgramZh,
    myBooking: myBookingZh,
    profile: profileZh,
    bundle: bundleZh,
    navBar: navBarZh,
    notification: notificationZh,
    new: newZh,
    voucher: voucherZh,
    contactUs: contactUsZh
  },
};
