import { createContext, useReducer } from "react";
import GlobalReducer from "../reducers/globalReducer"

const globalInitState = {
  globalError: null,
  globalLoading: false,
};

export const GlobalContextStore = createContext(globalInitState);

const GlobalProvider = ({ children }) => {
  const [globalState, globalDispatch] = useReducer(GlobalReducer, {
    ...globalInitState,
  });

  return (
    <GlobalContextStore.Provider value={{ globalState, globalDispatch }}>
      {children}
    </GlobalContextStore.Provider>
  );
};

export default GlobalProvider;
