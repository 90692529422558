const primary = "#3E3E3E";
const secondary = "#9A9A9A";
const orange = "#FF7925";
const lightOrange = "#FA804C";
const blue = "#002366";
const seaBlue = "#204EE5";
const cyan = "#C6EBFB";
const lightCyan = "#F0FAFF";
const white = "#fff";
const black = "#000";
const grey = "#4A4A4A";
const lightGrey = "#8C8C8C";
const error = "#F00";
// const blue = "#517E93";
// const orange = "#CB5D38";
const greyBg = "#e0e0dc";
const lightgreyBg = "#EFF0E8";
const whitegreyBg = "#F1F1F1";

export default {
  primary,
  secondary,
  white,
  black,
  grey,
  error,
  blue,
  seaBlue,
  cyan,
  lightCyan,
  orange,
  lightOrange,
  greyBg,
  lightGrey,
  lightgreyBg,
  whitegreyBg,
};
