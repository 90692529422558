import styled from "styled-components";

const Title = styled.span`
  color: var(--palette-ss-black, #4a4a4a);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 234px;
  padding: 22px 25px;
  border-radius: 5px;
  background: #f7f7f7;

  &::placeholder {
    color: #c1c1c1;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
  }
`;

export default {
  Title,
  TextArea,
};
