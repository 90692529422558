import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import ImageOrangeBg from "../../assets/bg-navBarOrange.png";
import ImageYellowBg from "../../assets/bg-navBarYellow.png";
import IconLogo from "../../assets/ic-shishaLogo.png";

import DesktopNavList from "./DesktopList";
import MobileNavList from "./MobileList";
import { ResponsiveUI, breakpoint } from "utils/responsive";

const Container = styled.div`
  position: sticky;
  top: 0;
  height: 185px;
  width: 100%;
  padding: 15px 25px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url(${ImageOrangeBg});
  background-color: transparent;
  z-index: 990;

  @media ${breakpoint.screen1024} {
    padding: 25px 35px;
  }

  @media ${breakpoint.mobile} {
    height: 100px;
    background-size: 100% 80px;
    padding: 12.12px 20px 15px 16.66px;
  }
`;

const Logo = styled.img`
  height: 70px;
  width: 200px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  left: 25px;
  z-index: 992;

  @media ${breakpoint.screen1024} {
    top: 25px;
    left: 35px;
  }

  @media ${breakpoint.mobile} {
    width: 120px;
    height: 40px;
  }
`;

const YellowIconContainer = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 198px;
  z-index: 991;

  @media ${breakpoint.mobile} {
    height: 90px;
  }
`;

const NavBar = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <YellowIconContainer src={ImageYellowBg} />
      <Logo src={IconLogo} onClick={() => navigate("/")} />
      <div className="w-full flex justify-end">
        <DesktopNavList />
        {/* <ResponsiveUI
          mobile={<MobileNavList />}
          desktop={<DesktopNavList />}
          mobileOffset={200}
        /> */}
      </div>
    </Container>
  );
};

export default NavBar;
