import { authedInstance, instance } from ".";
import { NotificationHistory } from "../models/Notification";

export async function registerDeviceToken({ deviceToken, platform }) {
  try {
    console.log("Register Device: ", {
      deviceToken,
      os: platform,
    });
    const res = await authedInstance.post("/v1/notification/registerDevice", {
      deviceToken,
      os: platform,
    });
    console.log("Register Device: ", res);
    return res.data;
  } catch (err) {
    console.log("Register Device: ", err);
  }
}

export async function unregisterDeviceToken() {
  try {
    const res = await authedInstance.post("/v1/notification/unregisterDevices");
    console.log("Unregister Device: ", res);
  } catch (err) {
    console.log("Unregister Device: ", err);
  }
}

export async function listGeneralNotifications({ skip, limit }) {
  const filter = {};
  filter.skip = skip;
  filter.limit = limit;

  var urlPath = "/v1/notification/topicPushHistory";
  urlPath += `?${new URLSearchParams(filter).toString()}`;

  const res = await authedInstance.get(urlPath);

  return {
    total: res?.data?.total,
    notifications: res?.data?.history?.map(
      (notificationHistory) => new NotificationHistory(notificationHistory)
    ),
  };
}

export async function listMessageNotifications({ skip, limit }) {
  const filter = {};
  filter.skip = skip;
  filter.limit = limit;

  var urlPath = "/v1/notification/directPushHistory";
  urlPath += `?${new URLSearchParams(filter).toString()}`;

  const res = await authedInstance.get(urlPath);

  return {
    total: res?.data?.total,
    notifications: res?.data?.history?.map(
      (notificationHistory) => new NotificationHistory(notificationHistory)
    ),
  };
}

export async function getNotificationDetail({ notificationId }) {
  const res = await authedInstance.get(
    `/v1/notification/pushHistory/${notificationId}`
  );

  console.log("Get Notification Detail: ", res.data);
  return new NotificationHistory(res?.data);
}
