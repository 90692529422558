import PageHeader from "components/common/PageHeader";
import ImageBanner from "assets/bg-tnc-banner.png";
import React from "react";
import Space from "components/common/Space";
import { useTranslation } from "react-i18next";

import { ReactComponent as IconFacebook } from "../assets/ic-facebook.svg";
import { ReactComponent as IconInstagram } from "../assets/ic-instagram.svg";
import styled, { css } from "styled-components";
import Typography from "components/common/Typography";
import theme from "utils/theme";
import PageContainer from "components/common/PageContainer";
import { getLocalizedString } from "utils/locale";
import ColorBannerCard from "pageComponents/facility/ColorBannerCard";
import cn from "classnames";
import GeneralButton from "components/common/Button";
import { ResponsiveUI, WindowSize } from "utils/responsive";
import Carousel from "components/common/Carousel";
import ImageContactUs3en from "assets/img-contactUs3-en.jpg";
import ImageContactUs3zh from "assets/img-contactUs3-zh.jpg";
import IconLogo from "assets/ic-shishaLogo.png";
import IconBistroLogo from "assets/ic-sportsBistroLogo.png";

const SubTitle = ({ title }: { title: string }) => {
  return (
    <div
      className={cn(
        "bg-[#F1F1F1]",
        "border-b",
        "border-[#D8D8D8]",
        "border-solid",
        "h-auto",
        "w-full",
        "px-[20px]",
        "py-[10px]"
      )}
    >
      <Typography color={theme.blue} fontWeight={700} textAlign="justify">
        {title}
      </Typography>
    </div>
  );
};

const Section = ({ title, content }: { title?: string; content?: string }) => {
  return (
    <div
      className={cn(
        "bg-[#fff]",
        "border-b",
        "border-[#D8D8D8]",
        "border-solid",
        "px-[20px]",
        "py-[10px]"
      )}
    >
      <Typography fontWeight="700" textAlign="justify">
        {title}
      </Typography>
      <Typography textAlign="justify">{content}</Typography>
    </div>
  );
};

const PHOTOS_EN = [ImageContactUs3en];
const PHOTOS_ZH = [ImageContactUs3zh];

const ContactUs = () => {
  const { t, i18n } = useTranslation("navBar");
  const isMobile = WindowSize() === "mobile";

  return (
    <div>
      <div className="mt-[-40px] md:hidden">
        <Carousel item={i18n.language === "en" ? PHOTOS_EN : PHOTOS_ZH} />
      </div>
      <PageContainer title={t("footer.contactSaiShaSportClub")}>
        <div>
          <div className="hidden md:block">
            <Carousel item={i18n.language === "en" ? PHOTOS_EN : PHOTOS_ZH} />
          </div>
          <img
            src={IconLogo}
            className="mb-[20px] w-full md:w-[335px] md:mt-[50px]"
          />
          <Typography textAlign="justify">
            {getLocalizedString(
              "GO PARK Sports will commence trial operation in August, during which the facilities are undergoing enhancements. We appreciate your patience and understanding. We hope to gather feedback from different stakeholders to optimize the facilities, and we will make our utmost efforts to provide every user with a high-quality sports venue.\n\nWe sincerely look forward to your support and valuable feedback. Should you have any comments or inquiries, please feel free to reach out to us any time. We are deeply grateful for your support.",
              "GO PARK Sports 將於 8 月起展開試營運計劃。在此階段，我們的設施仍在進行完善工程，敬請各位見諒。我們希望能夠收集各界人士的意見以優化GO PARK Sports的設施。我們會竭盡所能為各使用者提供一個優質的運動場所。\n\n我們衷心期待您的支持和提供寶貴的意見。如有任何意見或疑問，歡迎隨時與我們聯繫。感謝您的支持!"
            )}
          </Typography>

          <Space size="20px" />
          <Typography fontSize="20px" fontWeight="500" textAlign="justify">
            {getLocalizedString(
              "Overview of GO PARK Sports Sports Facilities",
              "GO PARK Sports運動設施一覽"
            )}
          </Typography>
          <Space size="20px" />

          <ColorBannerCard
            title={getLocalizedString("GO PARK Sports", "GO PARK Sports")}
          >
            <SubTitle
              title={getLocalizedString("General Sports", "基礎運動")}
            />
            <Section
              title={getLocalizedString(
                "Cycling and Running Track",
                "單車徑及跑步徑"
              )}
              content={getLocalizedString(
                "A 1.5-kilometer long 6m width track paved with smooth and skid-resistant asphalt, ensures a stable and safe surface for smooth cycling and running. The track is suitable for cycling criterium race and Duathlon.",
                "全長達1.5公里、闊最少6米的徑道舖設瀝青，使表面平整且防滑，令行車時平穩順暢，並提高安全及穩定性。設施足以支援專業單車公路繞圈賽以及結合單車和跑步的陸上兩項鐵人比賽，確保安全及高水平賽事的進行。"
              )}
            />

            <SubTitle
              title={getLocalizedString("Popular Sports", "熱門運動")}
            />
            <Section
              title={getLocalizedString("Golf", "高爾夫球")}
              content={getLocalizedString(
                "The only public golf driving range in Hong Kong equipped with Trackman radar technology on each and every bay. With a total of 70 bays, players can view real-time shot data on mobile devices and engage in simulated 18-hole competitions. Adjacent to the practice range is a 25,000-square-feet short game area with artificial turf, including practice green, chipping area, and multiple bunkers, providing comprehensive training support.",
                "全港唯一於每一條球道具備Trackman雷達科技的公眾高爾夫球練習場，球道多達70條，用家可實時以手機查看擊球數據及和外界進行十八洞模擬比賽。練習場旁更設有佔地25,000呎的仿真草短桿練習區，包括練習果嶺、切杆區及多個沙坑，支援全方位訓練 。"
              )}
            />
            <Section
              title={getLocalizedString("Tennis", "網球")}
              content={getLocalizedString(
                "6 x ITF-standard tennis courts, meeting the requirements set by the International Tennis Federation.",
                "6個符合國際網球聯合會規定的比賽場地 (ITF Standard) 的標準網球場。"
              )}
            />
            <Section
              title={getLocalizedString("Football/Rugby", "足球/ 欖球")}
              content={getLocalizedString(
                "An 11-a-side artificial turf football pitch, which can be divided into 2 7-a-side football pitches and can also be used as a rugby pitch.",
                "11人仿真人造草地足球場，可作兩個7人足球場之用，更可作欖球場地。"
              )}
            />

            <SubTitle
              title={getLocalizedString("Newly Emerged Sports", "新興運動")}
            />
            <Section
              title={getLocalizedString("Padel", "板網球")}
              content={getLocalizedString("5 x Padel courts", "5個板網球場")}
            />
            <Section
              title={getLocalizedString("Pickleball", "匹克球")}
              content={getLocalizedString(
                "2 x Pickleball courts",
                "2個匹克球場"
              )}
            />
            <Section
              title={getLocalizedString("Lacrosse", "棍網球")}
              content={getLocalizedString("Lacrosse field", "棍網球場")}
            />

            <SubTitle title={getLocalizedString("Urban Sports", "城市運動")} />
            <Section
              title={getLocalizedString("Basketball", "籃球")}
              content={getLocalizedString(
                "3 x 3-on-3 basketball courts",
                "3個3人籃球場"
              )}
            />
            <Section
              title={getLocalizedString("Roller Skating Rink", "滾軸溜冰場")}
            />
          </ColorBannerCard>

          <img
            src={IconBistroLogo}
            className="mb-[20px] w-full md:w-[335px] md:mt-[40px]"
          />
          <Space size="20px" />

          <ColorBannerCard
            title={getLocalizedString("SPORTS Bistro", "SPORTS Bistro")}
          >
            <SubTitle title={getLocalizedString("Introduction", "簡介")} />
            <Section
              content={getLocalizedString(
                "SPORTS Bistro promotes healthy menu recommended by nutritionists\nTo fully cater to the needs of sports enthusiasts, GO PARK Sports will operate SPORTS Bistro – a restaurant offering healthy menu recommended by professional nutritionists, tailored to fulfill the nutritional requirements of athletes. An enticing array of food and beverage options are also available for individuals and families.",
                "餐廳提供由專業營養師推薦的餐單為全面照顧運動人士所需，GO PARK Sports將營運SPORTS Bistro餐廳，由專業營養師推薦「健康飲食餐單」，配合專業運動員的營養需要，更同時提供適合一家大小以至不同人士的多樣化餐飲選擇"
              )}
            />

            <SubTitle title={getLocalizedString("Business hour", "營業時間")} />
            <Section
              content={getLocalizedString(
                "Monday: Closed\nTuesday - Friday: 12:00pm-9:00pm\nSaturday, Sunday and Public Holliday: 11:00am-9:00pm",
                "星期一: 休息\n星期二至星期五: 中午12點-下午9點\n星期六、日及公衆假期: 早上11點-下午9點"
              )}
            />

            <SubTitle title={getLocalizedString("Location", "地點")} />
            <Section
              content={getLocalizedString(
                "Shop 206, 2/F, GO PARK Sai Sha",
                "西沙GO PARK 2樓206號鋪"
              )}
            />

            <SubTitle
              title={getLocalizedString(
                "Age Limit / Dress Code",
                "年齡限制/著裝要求"
              )}
            />
            <Section
              content={getLocalizedString(
                "No age limit. Casual Attire",
                "無年齡限制。休閒服裝"
              )}
            />
          </ColorBannerCard>

          <Space size="40px" />

          <ColorBannerCard
            title={getLocalizedString("Public Transportation", "公共交通工具")}
          >
            <SubTitle title={getLocalizedString("Bus", "巴士")} />
            <Section
              content={getLocalizedString(
                "KMB 287: University Station (East Rail Line) \u21C6 GO PARK Sai Sha",
                "九巴 287: 大學站（東鐵綫）\u21C6 西沙GO PARK"
              )}
            />
            <Section
              content={getLocalizedString(
                "City Bus 581: Ma On Shan Town Centre (via Wu Kai Sha Station) \u21C6 GO PARK Sai Sha",
                "城巴 581: 馬鞍山市中心（經烏溪沙站）\u21C6 西沙GO PARK"
              )}
            />
            <Section
              content={getLocalizedString(
                "City Bus 582: University Station (East Rail Line) \u21C6 GO PARK Sai Sha",
                "城巴 582: 大學站（東鐵綫） \u21C6 西沙GO PARK"
              )}
            />

            <SubTitle title={getLocalizedString("Minibus", "小巴")} />
            <Section
              content={getLocalizedString(
                "807K : University Station (East Rail Line) \u21C6 Tseng Tau Tsuen (get off at GO PARK Sai Sha)",
                "807K : 大學站（東鐵綫）\u21C6 井頭村（於西沙GO PARK下車）"
              )}
            />
          </ColorBannerCard>

          <div className="flex justify-center mt-[40px]">
            <GeneralButton
              fullWidth={isMobile}
              onClick={() =>
                window.open("https://forms.gle/bD2ddbYoJE2hP4eN9", "_blank")
              }
            >
              {getLocalizedString("Booking & Enquiry", "預約及查詢")}
            </GeneralButton>
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default ContactUs;
