import styled from "styled-components";
import { breakpoint } from "utils/responsive";
import theme from "utils/theme";
import PageHeader from "./PageHeader";

interface PageProps {
  banner?: string;
  title?: string;
  children: React.ReactNode;
}

const Content = styled.div`
  background-color: ${theme.lightCyan};
  border-radius: 15px;
  width: 100%;
  max-width: 1280px;
  height: auto;
  padding: 50px 50px 240px;
  margin-top: 70px;

  @media ${breakpoint.screen1280} {
    margin: 70px auto 0px;
  }

  @media ${breakpoint.mobile} {
    margin-top: 40px;
    padding: 20px 20px 200px;
  }
`;

const PageContainer = ({ children, banner, title }: PageProps) => {
  return (
    <div>
      {banner && title && <PageHeader src={banner} title={title} />}
      <Content>{children}</Content>
    </div>
  );
};

export default PageContainer;
