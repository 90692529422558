import useMediaQuery from "@mui/material/useMediaQuery";

export const size = {
  mobile: 860,
  desktop: 861,
  screen1024: 1024,
  screen1280: 1280,
};

export const breakpoint = {
  mobile: `(max-width: ${size.mobile}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
  screen1024: `(min-width: ${size.screen1024}px)`,
  screen1280: `(min-width: ${size.screen1280}px)`,
};

type ResponsiveUIProps = {
  desktop: JSX.Element;
  mobile: JSX.Element;
  mobileOffset?: number;
};

export const ResponsiveUI = ({
  desktop,
  mobile,
  mobileOffset = 0,
}: ResponsiveUIProps) => {
  const matches = useMediaQuery(
    `(min-width: ${size.desktop + mobileOffset}px)`
  );

  if (matches && desktop) {
    return desktop;
  } else {
    return mobile;
  }
};

export const WindowSize = () => {
  const matchesDesktop = useMediaQuery(`(min-width: ${size.desktop}px)`);
  const matchesScreen1024 = useMediaQuery(`(min-width: ${size.screen1024}px)`);
  const matchesScreen1280 = useMediaQuery(`(min-width: ${size.screen1280}px)`);

  if (matchesScreen1024) {
    return "screen1024";
  } else if (matchesScreen1280) {
    return "screen1280";
  } else if (matchesDesktop) {
    return "desktop";
  } else {
    return "mobile";
  }
};
