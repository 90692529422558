import i18n from "../i18n";

export const getLocalizedString = (enString: string, zhString: string) => {
  switch (i18n.language) {
    case "en":
      return enString;
    case "zh":
      return zhString;
    default:
      return enString;
  }
};
