import * as React from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Modal, { ModalProps } from "@mui/material/Modal";

const style = {
  height: "100%",
  width: "100%",
  display: "grid",
  placeItems: "center",
};

type Props = {
  children: React.ReactElement;
};

const StyledModal = styled(Modal)`
  & .MuiModal-backdrop {
    background-color: rgb(0, 0, 0, 0.7) !important;
  }
`;

export type BaseModalProps = Omit<ModalProps, "children">;

const BaseModal: React.FC<Props & ModalProps> = (props: Props & ModalProps) => {
  const { children } = props;
  return (
    <StyledModal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{children}</Box>
    </StyledModal>
  );
};

export default BaseModal;
