import {
  APPEAR_GLOBAL_ERROR,
  DISAPPEAR_GLOBAL_ERROR,
  APPEAR_GLOBAL_LOADING,
  DISAPPEAR_GLOBAL_LOADING,
} from "../actions/globalAction";

const GlobalReducer = (state, action) => {
  switch (action.type) {
    case APPEAR_GLOBAL_ERROR:
      return {
        ...state,
        globalError: {
          message: action.message,
        },
      };
    case DISAPPEAR_GLOBAL_ERROR:
      return { ...state, globalError: null };
    case APPEAR_GLOBAL_LOADING:
      return { ...state, globalLoading: true };
    case DISAPPEAR_GLOBAL_LOADING:
      return { ...state, globalLoading: false };
  }
};

export default GlobalReducer;
