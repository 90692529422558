const config = {
  HOSTNAME: process.env.REACT_APP_HOSTNAME,
  API_ROOT: process.env.REACT_APP_API_ROOT,
  API_TIMEOUT: parseInt(process.env.REACT_APP_API_TIMEOUT || "180", 10),
  MONTHLY_SPORTPASS_RENEW_MODLA: parseInt(
    process.env.REACT_APP_MONTHLY_SPORTPASS_RENEW_MODLA || "30"
  ),
  ANNUAL_SPORTPASS_RENEW_MODLA: parseInt(
    process.env.REACT_APP_ANNUAL_SPORTPASS_RENEW_MODLA || "30"
  ),
  NUM_OF_SLOT_CAN_BE_SELECT_AT_ONCE: parseInt(
    process.env.REACT_APP_NUM_OF_SLOT_CAN_BE_SELECT_AT_ONCE || "1"
  ),
  FACILITY_AVAILBLE_DAY: parseInt(
    process.env.REACT_APP_FACILITY_AVAILBLE_DAY || "14"
  ),
  HIDE_MONTHLY_PROGRAM: true,
  HIDE_PRIVATE_COACH: true,
  HIDE_VOUCHER: true,
  HIDE_PROMO_CODE: true,
  HIDE_BUNDLE: true,
};

export default config;
