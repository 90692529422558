import { authedInstance, instance } from ".";

export async function login(payload) {
  const res = await instance.post("/v1/auth/saisha/login", payload);
  console.log("Login res: ", res.data, instance.getUri());
  authedInstance.defaults.headers.Authorization =
    "Bearer " + res.data.access_token;
  return res.data;
}

export async function uploadImageToS3(url, buffer) {
  try {
    const response = await instance.put(url, buffer, {
      headers: {
        'content-type': '',
      },
    });
    return response;
  } catch (e) {
    console.error('Failure to upload image to s3');
    console.error(e.message);
  }
}

export async function getPresignedURL(isPublic, imgFormat) {
  try {
    const response = await instance.post(
      'v1/common/presignedUrl/',{category: 'USEFUL_INFO', MIMEType: 'image'}
    );
    return response.data.result;
  } catch (e) {
    console.error('Failure!');
    console.error(e);
  }
}

export async function register(payload) {
  const res = await instance.post("/v1/auth/saisha/register", payload);
  console.log("Register res: ", res.data, instance.getUri());
  return res.data;
}

export async function forgetPassword(payload) {
  const res = await instance.post("/v1/auth/saisha/forget-password", payload);
  console.log("forgetPassword res: ", res.data, instance.getUri());
  return res.data;
}

export async function resetPassword(payload) {
  const res = await instance.post("/v1/auth/saisha/reset-password", payload);
  console.log("resetPassword res: ", res.data, instance.getUri());
  return res.data;
}

export async function genOtp(email) {
  const res = await instance.get("/v1/user/getOtp?" + new URLSearchParams({email}).toString());
  console.log("genOtp res: ", res.data, instance.getUri());
  return res.data;
}

export async function verifyOtp(payload) {
  const res = await instance.post("/v1/user/verifiyEmailOtp",payload);
  console.log("verifyOtp res: ", res.data, instance.getUri());
  return res.data;
}

export async function refreshToken(payload) {
  const res = await instance.post("/v1/auth/saisha/login/refresh", payload);
  console.log("refreshToken res: ", res.data, instance.getUri());
  authedInstance.defaults.headers.Authorization =
    "Bearer " + res.data.access_token;
  return res.data;
}